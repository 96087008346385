/*#Fonts*/


/*   @font-face {
      font-family: 'in-pre';
      src: url('../wp-content/webfonts/in.woff2') format('woff2');
      font-display: optional; /* Fix flickering 
      font-weight: normal;
      font-style: italic;
      unicode-range: U+0000-00FF;
  } */

  @font-face {
      font-family: 'in';
      src: url('../wp-content/webfonts/in.woff2') format('woff2');
      font-display: optional;
      font-weight: normal;
      font-style: italic;
      unicode-range: U+0000-00FF;
  }

  @font-face {
    font-family: 'brush';
    src: url('../wp-content/webfonts/brush.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0000-00FF;
}

  @font-face {
      font-family: 'out';
      src: url('../wp-content/webfonts/out.woff2') format('woff2');
      font-weight: normal;
      font-style: italic;
      unicode-range: U+0000-00FF;
  }

/*   @font-face {
      font-family: 'brush-pre';
      src: url('../wp-content/webfonts/brush.woff2') format('woff2');
      font-display: optional;
      font-weight: normal;
      font-style: normal;
  } */



  @font-face {
      font-family: "Bandeins Strange Variable";
      src: url('../wp-content/webfonts/BandeinsStrangeVariable.woff2') format('woff2'),
            url('../wp-content/webfonts/BandeinsStrangeVariable.ttf') format('ttf');
      font-weight: 300 700;
      font-stretch: 100% 800%;
      unicode-range: U+004D,U+0045,U+004E,U+0055;
  }

  @font-face {
      font-family: 'Waiting for the Sunrise';
      src: url('../wp-content/webfonts/waiting.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      unicode-range: U+0000-00FF;
  }

  @font-face {
      font-family: 'Kenjo II';
      src: url('../wp-content/webfonts/KenjoII.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
      unicode-range: U+0000-00FF;
  }

  @font-face {
    font-family: 'FontAwesome';
    src: url('../wp-content/webfonts/fontawesome-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal
}


  @font-face {
      font-family: "RoadRage";
      src: url("../wp-content/webfonts/RoadRage.woff2") format("woff2");
      font-weight: normal;
      font-style: normal;
      unicode-range: U+0000-00FF;
  }

  @font-face {
    font-family: 'PoiretOne';
    src: url('../wp-content/webfonts/PoiretOne.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
/*
  @font-face {
      font-family: "AtariClassic-Regular";
      src:url("../wp-content/webfonts/AtariClassic.woff") format("woff"), url("../wp-content/webfonts/AtariClassic.woff2") format("woff2");
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'christopherhandregular';
      src: url('../wp-content/webfonts/comesinhandy.woff2') format('woff2'), url('../wp-content/webfonts/comesinhandy.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }*/

/*#CSS-RESET*/
    /***
        The new CSS reset - version 1.4.9 (last updated 11.2.2022) https://github.com/elad2412/the-new-css-reset
        Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
        - The "symbol *" part is to solve Firefox SVG sprite bug
    ***/

    *:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
        all: unset;
        display: revert;
    }

    /* Preferred box-sizing value */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    /* Reapply the pointer cursor for anchor tags */
    a, button {
        cursor: revert;
    }

    /* Remove list styles (bullets/numbers) */
    ol, ul, menu {
        list-style: none;
    }

    /* removes spacing between cells in tables */
    table {
        border-collapse: collapse;
    }

    /* revert the 'white-space' property for textarea elements on Safari */
    textarea {
        white-space: revert;
    }

    /* minimum style to allow to style meter element */
    meter {
        -webkit-appearance: revert;
        appearance: revert;
    }

    /* reset default text opacity of input placeholder */
    ::placeholder {
        all: unset;
    }

    /* fix the feature of 'hidden' attribute.
      display:revert; revert to element instead of attribute */
    :where([hidden]) {
        display: none;
    }

    /* revert for bug in Chromium browsers
      - fix for the content editable attribute will work properly. */
    :where([contenteditable]) {
        -moz-user-modify: read-write;
        -webkit-user-modify: read-write;
        overflow-wrap: break-word;
        -webkit-line-break: after-white-space;
    }

    /* apply back the draggable feature - exist only in Chromium and Safari */
    :where([draggable="true"]) {
        -webkit-user-drag: element;
    }

    ul {
      display: block;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
    }

  .gs-dev-tools {
    z-index: 1000;
  }

:root {
  --diag: math hypot(vw, vh);
  --mewidth: calc((90vh * 1.257297297) / 3.8);
  --meleft: calc(clamp(0%, 90vh - 90vw, var(--mewidth)));
  --margin-height: calc(-58 * (82vh / 100));
}

html {
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body, html {
  margin: 0;
  padding: 0;
}

body {
  --acc-color: #bacb83;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #040419;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

.scrollbar-track {
  display: none !important;
}
.scroller {
  height:100vh;
}


/*#id04r*/
/*   #id04:checked + label.animationToggle {
    transform: rotate(360deg);
    background-color: #0006;
  }

  #id04:checked + label.animationToggle:before {
    transform: translateX(2.5em);
    background-color: #ecf561;
  }


  label.animationToggle {
    font-size: calc(clamp(0.5rem, 0.3rem + 0.35vw, 1.7rem));
    display: flex;
    width: 4.6em;
    height: 2em;
    border: 0.18em solid;
    border-radius: 99em;
    position: relative;
    transition: transform 0.75s ease-in-out;
    transform-origin: 50% 50%;
    cursor: pointer;
    pointer-events: all;
  }

  label.animationToggle:before {
    transition: transform 0.75s ease;
    transition-delay: 0.5s;
    content: "";
    display: block;
    position: absolute;
    width: 1.68em;
    height: 1.68em;
    background-color: #aca437bf;
    border-radius: 50%;
    top: 0.187em;
    left: 0.187em;
    border-color: #000000;
  } */
    #id04:checked  ~ .animationToggle {
    transform: rotate(360deg);
    background-color: #000000f2;
    transition: transform 0.75s ease-in-out;
  }

  #id04:unchecked  ~ .animationToggle {
    transform: rotate(0deg);
    background-color: #00000000;
    transition: transform 0.75s ease-in-out;
  }

/*   .animationToggle {
    transform: rotate(360deg);
    background-color: #000000f2;
  } */

  #id04:checked ~ .animationToggle::before {
    transform: translateX(1.75rem);
    transition: transform 0.75s ease-in-out;
    transition-delay: 0.5s;
    /* background-color: #ecf561; */
    /* background-image: linear-gradient(to right top, rgb(1, 255, 255), rgb(198, 1, 230)); */
  }

  label[for=id04] {
    /* font-size: calc(clamp(0.5rem, 0.3rem + 0.35vw, 1.7rem)); */
    display: inline-flex;
    width: 3rem;
    height: 1.25rem;
    border: 2.3px solid;
    border-radius: 0.75rem;
    position: relative;
    transition: transform 0.75s ease-in-out;
    transform-origin: 50% 50%;
    cursor: pointer;
    pointer-events: all;
    border-color: #f1f377;
    align-items: center;
    z-index: 2;
  }

  label[for=id04]::before {
    transition: transform 0.75s ease;
    transition-delay: 0.5s;
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 0.75rem;
    transform: translateX(0.0rem);
    background-image: linear-gradient(to right top, rgb(1, 255, 255), rgb(198, 1, 230));
  }

.cls-1{
  display: none;
}

/*#Shutter
  .loading-container {
    display: block;
    text-align: center;
    min-height: 100vh;
    overflow: hidden;
    z-index: 1000;
  }

  .loading-bg,
  .loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    pointer-events: none;
  }

  .loading-bg {
    background-color: #bacb83;
    z-index: 900;
  }

  .loading-lines-container {
    width: 100vw;
    height: 100vh;
    z-index: 1001;
  }

  .loading-word-reveal {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 1001;
  }

/*   .loading-word-mask {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* overflow: hidden;
    z-index: 1000;
    width: 100%;
    filter: drop-shadow(11px 23px 7px rgba(0, 0, 0, 0.4));
  }

  .loading-word {
    font-family: 'in';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -2px;
    position: relative;
    display: inline-block;
    color: #161616;
    font-size: 3.9vw;
    line-height: 1em;
    z-index: 1000;
    text-shadow: 0 0 #e3e0e0, 1px 1px 0px #c7c2c2, 2px 2px 0px #ddd5d5, 3px 3px 0px #d3caca, 4px 4px 0px #656e46, 5px 5px 0px #656e46, 6px 6px 0px #656e46, 7px 7px 0px #98a669, 8px 8px 0px #98a669;
  } */

  @media (max-width: 1024px) {
    .loading-word {
      font-size: 6vw;
    }
  }

  @media (max-width: 767px) {
    .loading-word {
      font-size: 8vw;
    }
  }

  .loading-path {
    position: relative;
    display: inline-block;
    color: #161616;
    vertical-align: top;
    filter: drop-shadow(10px 19px 3px rgba(0, 0, 0, 0.4));
  }

  @media (max-width: 767px) {
    .loading-path {
      font-size: 10px;
    }
  }

  .loading-line {
    position: relative;
    display: block;
    width: 100vw;
    height: 30px;
    background-color: #bacb83;
  }
  /*#EndShutter*/

/*#Gradient-Button*/

  .button--grad{
        border-color: #dbafff2b;
        border-style: solid;
        border-width: 1px;
        cursor: pointer;
        pointer-events: all;
        padding: 0.7rem 2rem 0.7rem 2rem;
        background: linear-gradient(45deg,#dbafff61 0%, #24d5ff59 100%);
        color: #000000;
        overflow: hidden;
        position: relative;
        /* box-shadow: -1px 2px 11px 0px; */
        border-radius: 10px;
        font-family: 'in';
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        filter:
      drop-shadow(
        2px 4px 4px hsl(0deg 0% 0% / 0.3)
      )
      drop-shadow(
        4px 8px 8px hsl(0deg 0% 0% / 0.3)
      );
      }

  .button--grad span {
        display: block;
        position: relative;
        z-index: 1;
      }

  .button--grad > span {
        overflow: hidden;
        /* mix-blend-mode: difference; */
      }

  .button--grad:hover > span > span {
        animation: MoveRightInitial 0.15s forwards, MoveRightEnd 0.35s forwards 0.25s;
      }

  .button--grad:active {
    top: 4px;
    filter: none;
  }

  @keyframes MoveRightInitial {
        to {
          transform: translate3d(105%,0,0);
        }
      }

  @keyframes MoveRightEnd {
        from {
          transform: translate3d(-100%,0,0);
        }
        to {
          transform: translate3d(0,0,0);
        }
      }

  .button--grad::before,
      .button--grad::after {
        content: '';
        background: linear-gradient(45deg,#3077879e 0%, #dc91ff94 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

  .button--grad::before {
        width: 135%;
        -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
        clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
        transform: translate3d(-100%,0,0);
      }

  .button--grad:hover::before {
        transform: translate3d(0,0,0);
        transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      }

  .button--grad::after {
        width: 105%;
        transform: translate3d(100%,0,0);
        transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      }

  .button--grad:hover::after {
        transform: translate3d(0,0,0);
        transition: transform 0.01s 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      }


  /*#endGradient-Button*/


.services-ticker-block {
  position: relative;
  display: none;
  align-items: center;
  overflow-x: hidden;
  transform: translate(0px, 100%);
}


#mycanvas {
  background-image: url("../assets/noanimation.webp");
  background-size: cover;
  display: block;
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
  position: fixed;
  z-index: 0;
  /*touch-action: auto !important;*/
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1.0;
}

.wrangler {
  /* position: fixed;
  z-index: 60;*/
  width: 100%;
  height: 100%;
}

/* .wrangler>canvas {
  height: 150vh !important;
  width: 100vw !important;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 5;
}

.tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  z-index: -1;
  opacity: 1;
  background-size: cover;
  }
  /*background-color: #ff2f8f;*/
  /*  background-color: rgb(241,191,191);
}*/


.grad {
    background: linear-gradient(131deg, #9df9fd 25%, #ffd79b 35%, #d27a3b 44%, #080808 86%, #fff 100%) !important;
}

.staticCloudBg{
  background-image: url("../assets/noanimation.webp");
  background-size: cover;
}

/*#Contact form*/
  .close-container {
    position: absolute;
    width: 60px;
    height: 60px;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .leftright {
    height: 3px;
    width: 60%;
    position: absolute;
    /* margin-top: 24px; */
    background-color: #f3eded99;
    border-radius: 1.5px;
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }

  .rightleft {
    height: 3px;
    width: 60%;
    position: absolute;
    /* margin-top: 24px; */
    background-color: #f3eded99;
    border-radius: 1.5px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }

  .close {
    margin: 60px 0 0 5px;
    position: absolute;
  }

  .close-container:hover .leftright {
    transform: rotate(225deg);
    background-color: #f3eded99;
  }

  .close-container:hover .rightleft {
    transform: rotate(135deg);
    background-color: #f3eded99;
  }

  #contact_form {
    /* display: flex; */
    position: fixed;
    z-index: 510;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    visibility: hidden;
    overflow: hidden;
    background-color: #00000000;
    pointer-events: none;
  }

  .form_before, .form_after{
    visibility: visible;
    overflow: hidden;
  }

  #form_container {
    /* max-width: 80vw; */
    height: 66%;
    display: flex;
    width: 68%;
    padding: 50px;
    background: #3c343447;
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: flex-end;
    pointer-events: all;
  }
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  #form_container {
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
    }
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    #form_container {
      background-color: rgba(59, 56, 56, 0.8);
    }
  }
  #form_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: -40%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    pointer-events: none;
    transform: skew(-15deg);
  }

  /*left:to_right*/
  .marquee  {
    /* margin: 2rem 0; */
    font-size: 4.5vmax;
    overflow: hidden;
    font-family: "out";
    /*font-weight: 600;*/
    }

  .marquee--long{
    font-size: 3vmax;
    text-transform: uppercase;
  }

  .marquee  span {
    display: inline-block;
    white-space: nowrap;
    color: #ffffffc2;
    width: var(--tw);
    text-shadow: var(--tw) 0 currentColor,
                calc(var(--tw) * 2) 0 currentColor,
                calc(var(--tw) * 3) 0 currentColor,
                calc(var(--tw) * 4) 0 currentColor;
    will-change: transform;
  }



  #form_container .row100 {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  #form_container .row100 .col {
    position: relative;
    width: 100%;
    /*padding: 0 10px;*/
    margin: 30px 0 10px;
    /* transition: 7.5s; */
    display: flex;
    justify-content: center;
  }



  #form_container .row100 .inputBox {
    position: relative;
    width: 100%;
    height: 40px;
    color: #fff;
    overflow: hidden;
  }



  #form_container .row100 .inputBox input, #form_container .row100 .inputBox.textarea textarea {
    position: absolute;
    width: 90%;
    height: 100%;
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 0 10px;
    z-index: 1;
    color: #fff;
    margin-right: 10px;
  }

  #form_container .row100 .inputBox .text {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 40px;
    font-size: 20px;
    padding: 0 10px;
    display: block;
    transition: 0.5s;
    pointer-events: none;
    font-family: "roadrage";
  }

  #form_container .row100 .inputBox input:focus+.text, #form_container .row100 .inputBox input:valid+.text {
    top: -35px;
    left: -10px;
  }

  #form_container .row100 .inputBox .line {
    position: absolute;
    bottom: 0;
    display: block;
    width: 0%;
    height: 2px;
    background: #f5e9e93b;
    transition: 0.5s;
    border-radius: 2px;
    pointer-events: none;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  #form_container .row100 .inputBox input:focus~.line, #form_container .row100 .inputBox input:valid~.line {
    height: 100%;
  }

  #form_container .row100 .inputBox.textarea {
    position: relative;
    width: 100%;
    height: 100px;
    padding: 10px 0;
  }

  #form_container .row100 .inputBox.textarea textarea {
    height: 100%;
    resize: none;
  }

  #form_container .row100 .inputBox textarea:focus+.text, #form_container .row100 .inputBox textarea:valid+.text {
    top: -35px;
    left: -10px;
  }

  #form_container .row100 .inputBox textarea:focus~.line, #form_container .row100 .inputBox textarea:valid~.line {
    height: 100%;
  }

  input[type="submit"] {
    border: none;
    padding: 7px 35px;
    cursor: pointer;
    outline: none;
    background: #fff;
    color: #000000;
    font-size: 18px;
    border-radius: 2px;
  }

  /*#endContact form*/


/*#Footer*/
  .footer_section {
    /*margin-top: -47.5vh;*/
    margin-top: var(--margin-height);
    height: 82vh;
    vertical-align: bottom;
    position: absolute;
    z-index: 1;
    overflow: hidden;
  }

  #glass_icon_list {
    position: relative;
    display: flex;
    z-index: 70;
    padding: 0;
    margin-top: calc(var(--part-height) * 0.125);
    justify-content: center;
    pointer-events: all;
  }

  #glass_icon_list li {
    position: relative;
    margin: 10px;
  }

  #glass_icon_list li button, #glass_icon_list li a {
    position: relative;
    width: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
    height: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: clamp(1rem, 0.03rem + 6.18vw, 3rem);
    color: rgb(255, 255, 255);
    transition: 0.8s;
    backdrop-filter: blur(5px);
    overflow: hidden;
    cursor: pointer;
  }

  #glass_icon_list li button:hover,#glass_icon_list li a:hover {
    transform: translateY(10px);
  }

  #glass_icon_list li button::before,#glass_icon_list li a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(45deg) translateX(150px);
    transition: 0.5s;
  }

  #glass_icon_list li button:hover:before,#glass_icon_list li a:hover::before {
    transform: skewX(45deg) translateX(-150px);
  }

  .elementor-element.elementor-element-79ddde24 {
    min-height: 100vh;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 2em;
    z-index: 11;
  }

  .elementor-element-cbafa4c {
    pointer-events: none;
  }

  .elementor-element-5321b1b4 {
    margin-top: -47.5vh;
  }

  #tsparticles > canvas {
    position: relative !important ;
    height: 100%;
    grid-area: 1 / 1 / 3 / 7;
  }

/* ---- particles.js container ---- */

#tsparticles {
  position: absolute;
  width: 100%;
  height: 82vh;
  display: grid;
  grid-template-columns: 4.73% 40.52% repeat(2, 4.73%) 40.52% 4.73%;
  grid-template-rows: 28.55% 29.45% 42%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  vertical-align: bottom;
  background: linear-gradient( 0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 42%, rgba(255, 255, 255, 0) 60%);
  z-index: 11;
  align-items: stretch;
  --part-height: 100%;
}

.div1 {
  grid-area: 3 / 1 / 4 / 7;
}

.div2 {
  grid-area: 2 / 2 / 3 / 3;
}

.div3 {
  grid-area: 2 / 5 / 3 / 6;
}

.div4 {
  grid-area: 3 / 5 / 3 / 6;
}

.div5 {
  grid-area: 3 / 5 / 4 / 6;
}

.wrapper-footer {
  z-index: 75;
  width: 100vw;
  vertical-align: bottom;
}

#footer-bg {
  width: 100%;
  object-fit: unset;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  background: #000000;
}

#pyra_l {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: auto;
  margin-bottom: -1em;
}

#pyra_r {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: auto;
  z-index: 60;
  margin-bottom: -1em;
}

.div2 {
  z-index: 20;
  vertical-align: bottom;
  align-self: self-end;
}

.div3 {
  z-index: 20;
  align-self: self-end;
}

.div4 {
  justify-self: right;
  /*align-self: end;*/
  width: 9rem;
  height: 9rem;
  z-index: -1;
  }

#mond {
  width: 100%;
  height: auto;
}

#arrowup_mobile {
  width: 2em;
  height: 2em;
  z-index: 19;
  position: absolute;
  right: 1em;
  bottom: 11vh;
  cursor: pointer;
  opacity: 0.7;
  -webkit-animation: updown 5s ease infinite;
  -moz-animation: updown 5s ease infinite;
  -o-animation: updown 5s ease infinite;
  animation: updown 5s ease infinite;
}


#arrowup {
  width: 2em;
  height: 2em;
  z-index: 19;
  position: absolute;
  right: 2em;
  bottom: 15vh;
  cursor: pointer;
  opacity: 0.7;
  -webkit-animation: updown 5s ease infinite;
  -moz-animation: updown 5s ease infinite;
  -o-animation: updown 5s ease infinite;
  animation: updown 5s ease infinite;
}

@keyframes updown {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(0, 40px);
  }
  100% {
    transform: translate(0);
  }
}

.lve {
  z-index: 18;
  font-family: roadrage;
  height: auto;
  width: 100%;
  color: rgba(129, 121, 182, 0.56);
  text-decoration: none;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  text-align: center;
  margin-bottom: 0.2em;
}

.lve p {
  margin-block-end: 0;
}

.part {
  display: none;
}

.part_mob {
  display: none;
}

/*  ul:not(.fun *):not(.menu_mobile *) {
  position: relative;
  display: flex;
  z-index: 70;
  padding: 0;
  margin-top: calc(var(--part-height) * 0.125);
} */

#social-icons {
  position: relative;
  display: flex;
  z-index: 70;
  padding: 0;
  margin-top: calc(var(--part-height) * 0.125);
  justify-content: center;
}

.div6 ul li {
  position: relative;
  margin: 10px;
}

.div6 ul li a {
  position: relative;
  width: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
  height: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: clamp(1rem, 0.03rem + 6.18vw, 3rem);
  color: rgb(255, 255, 255);
  transition: 0.8s;
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.div6 ul li a:hover {
  transform: translateY(10px);
}

.div6 ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  transform: skewX(45deg) translateX(150px);
  transition: 0.5s;
}

.div6 ul li a:hover:before {
  transform: skewX(45deg) translateX(-150px);
}

.git-wrap {
  grid-area: 3 / 2 / 4 / 3;
}

.git-wrap>p:nth-child(1) {
  margin-top: calc(var(--part-height) * 0.125);
  font-size: clamp(3.19rem, 1.98rem + 1.90vw, 4.25rem);
  font-family: "in";
  background: linear-gradient(7deg, #4ff7b273 43%, #5012a3 59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #5d8b6338;
  text-align: center;
  -webkit-text-stroke: 1px #bacb83;
}

@media screen and (max-width: 1023px) {
  .wrapper-footer {
    display: none;
  }
  .part_mob {
    display: flex;
    width: 100vw;
    /* vertical-align: bottom; */
    flex-direction: column;
    /* flex-wrap: nowrap; */
    /* align-content: center; */
    /* justify-content: end; */
    /* align-items: center; */
    z-index: 1000;
  }
  #footer-mob {
    object-fit: unset;
    max-height: 90%;
    max-width: 100%;
    height: auto;
    bottom: 0px;
    position: absolute;
  }
  .wrap {
    z-index: 80;
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
  }
  .div6 {
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
    height: 82vh;
    align-items: center;
  }
  .elementor-element-1c4139f2 {
    margin-top: -65vh;
    z-index: 500;
  }
  .getintouch {
    z-index: 70;
    align-self: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: clamp(1.63rem, 1.18rem + 2.84vw, 3rem);
    font-family: "in";
    font-weight: 300;
    background: linear-gradient(7deg, #26d560c7 43%, #8179b6 59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #72bde985;
    padding: 10px;
    -webkit-text-stroke: 1px #bacb83;
  }
  .div6 ul {
    position: relative;
    display: flex;
    z-index: 70;
    padding: 0;
    margin-top: calc(var(--part-height) * 0.125);
    margin-bottom: 8%;
  }

  #form_container{
    height: 90%;
    max-width: 84vw;
    width: 84vw;
    padding: 1em;
  }
  #menew{
    mix-blend-mode: normal;
  }

}

/*menu_mobile*/

.menu_mobile {
  height: 100vh;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
  background-color: #000;
  font-family: 'Kenjo II';
  /*font-size: calc(60px + (160 - 60) * (100vw - 280px) / (1024-280));*/
  font-size: clamp(4.06rem, 2.61rem + 7.74vw, 8.13rem);
  clip-path: circle(60px at 100% -10%);
  -webkit-clip-path: circle(60px at 100% -10%);
  top: 0rem;
  right: 0;
  opacity: 1;
  pointer-events: all;
  /* padding-bottom: 0.5em; */
  /* visibility: hidden; */
  position: fixed;
}

.popover {
  /* box-shadow: 0px 6px 8px rgb(80 60 60 / 74%); */
  /* width: 100vw; */
  /* height: 100vh; */
}

.popover:target {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.popover .content {
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  width: 100%;
  height: 0;
  color: #fff;
  /* background-color: #191919; */
  animation: 1s grow ease forwards;
  text-align: left;
}

.nav_list {
  padding-inline-start: 0px;
}

.nav_list a {
  text-decoration: none;
  font-size: max(16vw, 30px);
  color: #fff;
}

.nav_list_item {
  height: 100%;
  overflow: hidden;
}

.nav_list li {
  /* padding: 15px 0; */
  text-transform: uppercase;
  transform: translateY(200px);
  opacity: 1;
  /*animation: 2s slideUp ease forwards 0.5s;*/
  position: relative;
  line-height: 1.2;
  letter-spacing: -0.1em;
  margin: -0.1em 0;
  overflow: hidden;
}

.nav_list li::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 0px;
  left: 0;
  bottom: 10px;
  background: #bf2e97;
  transition: all 0.5s ease;
}

.nav_list li:hover:before {
  width: 100%;
}

.popover p {
  padding: 50px;
  opacity: 0;
  width: 100%;
  height: 100%;
  animation: 1s fadeIn ease forwards 1s;
}

.popover .close::after {
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  z-index: 1;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  background-color: #bf2e97;
  color: #fff;
  content: '×';
  cursor: pointer;
  opacity: 0;
  animation: 1s fadeIn ease forwards 0.5s;
}

@keyframes grow {
  100% {
    height: 90%;
    width: 90%;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* widget: smoke_pixie_4.8 */

.journey_section{
  z-index: 100;
}

.text_glass_container {
  max-width: 100vw;
  min-height: 150vh;
  width: 60vw;
  display: flex;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.text_glass {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  background: #0000006b;
  box-shadow: 0 25px 45px rgb(0 0 0 / 33%);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  backdrop-filter: blur(25px) contrast(0.8);
  height: fit-content;
  align-self: center;
  visibility: hidden;
  word-break: break-word;
  }


.text_glass>* {
  color: #fff;
  font-family: 'Waiting for the Sunrise', Sans-serif;
  font-size: clamp(1.25rem, 0.3rem + 1.19vw, 2.38rem);
  font-weight: 200;
  text-shadow: 1px 0px 0px rgb(211 211 211);
  padding: 0px 1em 0 1em;
  margin: 0em 0 0 0;
  will-change: transform;
}

.text_glass_wrap {
  position: relative;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: nowrap;
  -ms-flex-line-pack: start;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* HERO-LAND-START*/



.hero-container {
  display: flex;
  width: 100%;
  height: 100vh;
  /*z-index: 10;
  background: linear-gradient(275deg, rgba(183, 33, 255, 1) 0%, rgba(33, 212, 253, 1) 100%);
  /*background:rgba(0, 0, 0, 0)*/
  overflow-x: hidden;
  background: transparent;
  align-items: flex-end;
}

.svg-container {
  width: 100vw;
  height: 100vh;
  z-index: 0;
  bottom: 0%;
}

#menew {
  position: absolute;
  bottom: 0;
  width: auto;
  height: 90vh;
  grid-area: 1;
  left: calc(-1 * var(--meleft));
  z-index: 15;
  /* mix-blend-mode: overlay; */
  opacity: 0.8;
}

.wrapper {
  position: absolute;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(50vh, 100vh);
  overflow: hidden;
}

.textblock {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  z-index: 15;
  bottom: calc(clamp(0.5rem, -6rem + 13vw, 50vh));
  right: calc(clamp(5.81rem, 17.6rem + -12.07vw, 3.13rem));
  margin-bottom: 3em;
}

.subt {
  z-index: 16;
  font-family: 'brush';
  color: #ddefa1;
  text-align: center;
  font-size: calc(clamp(0.8rem, 0.6rem + 1.35vw, 1.93rem));
  transform-origin: 50% 50%;
  font-weight: 300 !important;
  max-width: 100%;
  margin-top: 0.5em;
  -webkit-text-stroke: 0.7px #0d0206;
}

.subt_line {
  background-color: transparent;
  width:100%;
  overflow: hidden;
  line-height: 1.1;
}

.subt_word {
  display: inline-flex;
}

.subt_chars{
  perspective: 500px;
  position: relative;
  display: inline-block;
  backface-visibility: visible;
  transform-origin: 50% 50%;
  transform: rotateX(-360deg);
}

h1.splitted_lines {
  display: flex;
  padding: 0px 0.125em 0.5em 0.125em;
  /* flex-direction: row; */
  /* margin-bottom: 0.2em; */
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1, h2, h3, h4, h5, h6 {
  /* margin-top: 0.5rem; */
  /* margin-bottom: 1rem; */
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.splitted_words {
  position: relative;
  display: flex;
}

div.splitted_words:last-child {
  margin-left: 0.5em;
}

.splitted_chars {
  position: relative;
  display: inline-block;
  transform-origin: 50% 50%;
  z-index: 14;
  font-family: 'in';
  height: 1em;
  color: #2c242b40;
  /* text-decoration-line: underline; */
  text-align: left;
  text-shadow: rgb(0 0 0 / 52%) 2px 2px 4px;
  letter-spacing: 0px;
  font-weight: 500;
  --num: calc(clamp(1.88rem, 1.55rem + 3vw, 8.5rem));
  font-size: var(--num);
  text-transform: uppercase;
  -webkit-text-stroke: 2px #bacb83bd;
  will-change: transform;

}


/* HERO-END */

/* LOADER */

/*  .loading-container {
  display: block;
  pointer-events: none;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}

.loading-bg, .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
/*   opacity: 1;
  z-index: 9999;
  pointer-events: none;
}

.loading-bg {
  background-color: #1d50457a;
}

.loading-lines-container {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.loading-word-reveal {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 1001;
}

.loading-word-mask {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 9999;
}

.loading-word {
  font-family: Windsor W03 Light, serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  font-display: auto;
  position: relative;
  display: inline-block;
  color: #607d8b;
  font-size: 4.9vw;
  line-height: 1em;
  z-index: 9999;
}

@media (max-width: 1024px) {
  .loading-word {
    font-size: 6vw;
  }
}

@media (max-width: 767px) {
  .loading-word {
    font-size: 8vw;
  }
}

.loading-path {
  position: relative;
  display: inline-block;
  color: #161616;
  vertical-align: top;
  font-size: 1.5em;
  font-weight: 700;
}

@media (max-width: 767px) {
  .loading-path {
    font-size: 10px;
  }
}

.loading-line {
  position: relative;
  display: block;
  width: 100vw;
  height: 30px;
  background-color: #1d5045;
}  */

/*LOADER END*/
/*VERSUCH*/




/*END VERSUCH
/* widget: liquid_menu_final */

.liquid_menu_final {
  position: fixed;
  width: 100vw;
  z-index: 85;
}

canvas {
  height: 100%;
  width: 100%;
}



/* .toggler {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 60px;
  height: 60px;
  z-index: 99999;
  opacity: 0;

  pointer-events: all;
} */

.header_box {
	top: 0;
	z-index: 90;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	/* padding: 2rem; */
	justify-content: space-between;
	align-content: stretch;
	align-self: center;
	width: 98vw;
	margin: auto;
	height: 8vh;
  margin-bottom: -8vh;
}

#hamburger__container, #animationToggle__container {
  display: flex;
}
#hamburger__container {
  width: 4.7rem;
  height: 3.4rem;
  z-index: 2;
}

.hamburger {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
  background: #31313161;
  pointer-events: all;
  cursor: pointer;
  backdrop-filter: blur(30px);
}

.menu_word {
    position: absolute;
    top: 33px;
    color: #c9ef54ad;
    font-family: Bandeins Strange Variable;
    font-variation-settings: "wght" 700, "wdth" 800;
    font-size: 11px;
}

.hamburger>div {
  position: absolute;
  width: 60%;
  height: 2px;
  border-radius: 1.5px;
  background-color: var(--acc-color);
  transition: 0.4s;
}

.hamburger>div:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 1.5px;
  background-color: var(--acc-color);
  top: -10px;
  left: 0;
  transition: 0.4s;
}

/* .hamburger>div:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: var(--acc-color);
  top: 10px;
  left: 0;
  transition: 0.4s;
} */

.toggler:checked+.hamburger>div {
  transform: rotate(135deg);
}

.toggler:checked:hover+.hamburger>div {
  transform: rotate(225deg);
}

.toggler:checked+.hamburger>div:before, .toggler:checked+.hamburger>div:after {
  top: 0;
  transform: rotate(90deg);
}

#demo {
  z-index: 250;
}



.menu_desktop {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;
}

.fun {
  z-index: 210;
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translateX(-50%) translateY(-50%); */
  transform: translate(-50%, -50%) scale(0, 0);
  width: 85vw;
  height: 85vw;
  border-radius: 50%;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  background-image: url("../assets/5.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-family: Kenjo II;
  pointer-events: all;
}

.cursor {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 99999;
}

.menu_desktop>div>div {
  max-width: 100vw;
  max-height: 100vh;
  opacity: 1;
  transition: 0.4s;
}

.menu_desktop>div>div>ul>li>a {
  text-decoration: none;
  color: #fff;
  font-family: 'Kenjo II', Regular;
  font-weight: 550;
  text-transform: uppercase;
  margin: 0.5rem;
  transition: 0.3s;
  font-size: calc(clamp(1.375rem, 0.4646rem + 8.6024vw, 7.1875rem));
  display: inline-block;
}

.menu_desktop>div>div>ul>li>a:hover {
  color: #fff44f;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: scale(1.3);
}

/*
.toggler:checked~.menu {
    opacity: 1;
    visibility: visible;
}

.toggler:checked~.menu>div {
    transform: translateX(-50%) translateY(-50%);
}

.toggler:checked~.menu>div>div {
    opacity: 1;
}

*/

/*   Split hover menu start */

.global-menu__item {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: -0.4rem 0;
  /* letter-spacing: -.1em; */
}

.Menu-list {
  font-size: clamp(4.50rem, 2.30rem + 7.04vw, 10.75rem);
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotateX(20deg) rotateY(340deg);
  /* margin: 0; */
  padding: 0;
  /*font-size: 10.75rem;
  /* margin: -2.4rem; */
  justify-content: center;
}

.Menu-list-item {
  position: relative;
  color: transparent;
  cursor: pointer;
  transform: translate(0px, -100%);
  line-height: 0.9;
  /* overflow: hidden; */
  /* margin-top: -.4rem; */
  letter-spacing: -.1em;
  /* padding-right: 2em; */
  margin-bottom: -0.4rem;
}

.Menu-list-item::before {
  content: '';
  display: block;
  position: absolute;
  top: 49%;
  left: -10%;
  right: -10%;
  height: 4px;
  border-radius: 4px;
  margin-top: -2px;
  background: #ff2c75;
  transform: scale(0);
  transition: transform 0.8s cubic-bezier(0, -0.39, 0.95, 1.52);
  z-index: 1;
}

.Mask {
  display: block;
  position: absolute;
  overflow: hidden;
  color: #ff2c75;
  top: 0;
  height: 49%;
  transition: all 0.6s cubic-bezier(1, 0.1, 1, 1);
  width: 100%;
}

.Mask .Mask, .Mask a {
  /* color: #ff2c75; */
  color: white;
}

.Mask span {
  display: block;
}

.Mask+.Mask {
  top: 48.9%;
  height: 51.1%;
}

.Mask+.Mask span {
  transform: translateY(-49%);
}

.Menu-list-item:hover .Mask, .Menu-list-item:hover a, .Menu-list-item:active .Mask, .Menu-list-item:active a {
  color: #fff44f;
  transform: skewX(12deg) translateX(5px);
}

.Menu-list-item:hover .Mask+.Mask, .Menu-list-item:active .Mask+.Mask {
  transform: skewX(12deg) translateX(-5px);
}

.Menu-list-item:hover::before, .Menu-list-item:active::before {
  transform: scale(1);
}

/*   liquid-menu end*/

/* Mobile_Menu start*/

.uc_liquis_copy_bg_addon29003 {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
}

/*3Tilt Cards CSS

#3tilt {
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.container__cards {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(450px, min-content));
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  box-sizing: border-box;
}

.container__cards .card {
  position: relative;
  width: auto;
  margin: 20px;
  border-radius: 12px;
  display: inline-block;
  transition: 0.5s;
  overflow: hidden;
  backdrop-filter: blur(25px) saturate(200%);
  -webkit-backdrop-filter: blur(25xp) saturate(200%);
  box-sizing: border-box;
}

@media (hover: none), (hover: on-demand) {
  .container__cards .card:hover {
    transform: translateY(-10px);
  }
}

.container__cards .card .content {
  text-align: center;
  opacity: 1;
  transition: 0.5s;
  transform: translateZ(20px);
}

.container__cards .card .content h1 {
  font-size: calc(24px + (48 - 24) * (100vw - 320px) / (1920 - 320));
  color: #fff;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
  transition: 0.5s;
  transform: translateZ(20px);
}

.container__cards .card .content p {
  font-size: calc(18px + (22 - 18) * (100vw - 320px) / (1920 - 320));
  padding: 1em;
  padding-top: 4px;
  padding-bottom: 4px;
  /*color: #fff;*/
  text-align: justify;
  /*line-height: 1.5;*/
  margin-top: 0;
  margin-bottom: 0.9rem;
}

#card1 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23e80858' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00'/%3E%3Cstop offset='1' stop-color='%23e80858'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0px 10px 57px -13px #00f1bf00;
}

#card2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23362c84' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff'/%3E%3Cstop offset='1' stop-color='%23362c84'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0px 10px 57px -13px #05f53300;
}

#card3 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%2335ec72' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff'/%3E%3Cstop offset='1' stop-color='%2335ec72'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0px 10px 57px -13px rgba(0, 0, 0, 0);
}

/*ONEPAGER CSS*/

#wp-custom-css>.leiste {
  backdrop-filter: blur(20px) saturate 200%;
  -webkit-backdrop-filter: blur(20px) saturate 200%;
}

.glitch-typo, .glitch-typo-second {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.glitch-typo-second:hover, .glitch-typo:hover {
  text-decoration: none;
}

.glitch-typo-second:hover:before, .glitch-typo:hover:before {
  -webkit-animation: glitch-typo 2s 0.3s cubic-bezier(0, 0.82, 1, 0.42) both;
  animation: glitch-typo 2s 0.3s cubic-bezier(0, 0.82, 1, 0.42) both;
}

.glitch-typo-second:hover:after, .glitch-typo:hover:after {
  -webkit-animation: glitch-typo 2s 0.4s cubic-bezier(0, 0.82, 1, 0.42) reverse both;
  animation: glitch-typo 2s 0.4s cubic-bezier(0, 0.82, 1, 0.42) reverse both;
}

.glitch-typo-second:hover span, .glitch-typo:hover span {
  -webkit-animation: glitch-typo-scale 0.5s 0.1s ease-in-out;
  animation: glitch-typo-scale 0.5s 0.1s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.glitch-typo-second:after, .glitch-typo-second:before, .glitch-typo:after, .glitch-typo:before {
  content: attr(data-title);
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.glitch-typo-second:before, .glitch-typo:before {
  color: #4ff7b2;
}

.glitch-typo-second:after, .glitch-typo:after {
  color: #ef00d8;
}

.glitch-typo span, .glitch-typo-second span {
  position: relative;
  display: inline-block;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.glitch-typo-second:before {
  color: #6488dc;
}

@-webkit-keyframes glitch-typo {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  18% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  20% {
    -webkit-transform: translate(-10px, 10px);
    transform: translate(-10px, 10px);
    opacity: 0.6;
  }
  34% {
    -webkit-transform: translate(-16px, -10px);
    transform: translate(-16px, -10px);
    opacity: 0;
  }
  55% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0.4;
  }
  60% {
    -webkit-transform: translate(-10px, 10px);
    transform: translate(-10px, 10px);
    opacity: 0.8;
  }
  64% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    opacity: 0.4;
  }
  70% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
}

@-webkit-keyframes glitch-typo-scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes glitch-typo {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  18% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  20% {
    -webkit-transform: translate(-10px, 10px);
    transform: translate(-10px, 10px);
    opacity: 0.6;
  }
  34% {
    -webkit-transform: translate(-16px, -10px);
    transform: translate(-16px, -10px);
    opacity: 0;
  }
  55% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0.4;
  }
  60% {
    -webkit-transform: translate(-10px, 10px);
    transform: translate(-10px, 10px);
    opacity: 0.8;
  }
  64% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    opacity: 0.4;
  }
  70% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
}

@keyframes glitch-typo-scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  70% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

header h1 .logo-1 {
  opacity: 1;
}

header h1 .logo-2 {
  -webkit-filter: hue-rotate(60deg) saturate(4);
  filter: hue-rotate(60deg) saturate(4);
  -webkit-animation: glitch-logo 8s cubic-bezier(0, 0.82, 1, 0.42) reverse both infinite;
  animation: glitch-logo 8s cubic-bezier(0, 0.82, 1, 0.42) reverse both infinite;
}

header h1 .logo-3 {
  -webkit-filter: hue-rotate(270deg) saturate(4);
  filter: hue-rotate(270deg) saturate(4);
  -webkit-animation: glitch-logo 10s cubic-bezier(0, 0.82, 1, 0.42) both infinite;
  animation: glitch-logo 10s cubic-bezier(0, 0.82, 1, 0.42) both infinite;
}

@-webkit-keyframes glitch-logo {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  19% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  22% {
    -webkit-transform: translate(-10px, 10px) skew(-10deg, 0deg);
    transform: translate(-10px, 10px) skew(-10deg, 0deg);
    opacity: 0.4;
  }
  26% {
    -webkit-transform: translate(-10px, -20px);
    transform: translate(-10px, -20px);
    opacity: 0;
  }
  27% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  52% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  56% {
    -webkit-transform: translate(-10px, 10px) skew(10deg, 0deg);
    transform: translate(-10px, 10px) skew(10deg, 0deg);
    opacity: 0.8;
  }
  57% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0.4;
  }
  58% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
}

@keyframes glitch-logo {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  19% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  22% {
    -webkit-transform: translate(-10px, 10px) skew(-10deg, 0deg);
    transform: translate(-10px, 10px) skew(-10deg, 0deg);
    opacity: 0.4;
  }
  26% {
    -webkit-transform: translate(-10px, -20px);
    transform: translate(-10px, -20px);
    opacity: 0;
  }
  27% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  52% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  56% {
    -webkit-transform: translate(-10px, 10px) skew(10deg, 0deg);
    transform: translate(-10px, 10px) skew(10deg, 0deg);
    opacity: 0.8;
  }
  57% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0.4;
  }
  58% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 0;
  }
}

.footer_stars {
  display: grid;
  grid-area: 1 / 1 / 3 / 7;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  -webkit-transform: rotateZ(24deg);
  -moz-transform: rotateZ(24deg);
  -ms-transform: rotateZ(24deg);
  -o-transform: rotateZ(24deg);
  transform: rotateZ(24deg);
}

.shooting-stars {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  -webkit-transform: rotateZ(24deg);
  -moz-transform: rotateZ(24deg);
  -ms-transform: rotateZ(24deg);
  -o-transform: rotateZ(24deg);
  transform: rotateZ(24deg);
}

.star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 4px;
  border-radius: 999px;
  background: linear-gradient(-45deg, #fff, rgba(0, 0, 255, 0));
  -webkit-filter: drop-shadow(0 0 6px #fff);
  filter: drop-shadow(0 0 6px #fff);
  -webkit-animation: star-tail 10s ease-in-out infinite, star-shooting 10s ease-in-out infinite;
  animation: star-tail 10s ease-in-out infinite, star-shooting 10s ease-in-out infinite;
}

.star:nth-child(1) {
  margin: 500 0 0 -38%;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.star:nth-child(2) {
  margin: 166px 0 0 -10%;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.star:nth-child(3) {
  margin: -140px 0 0 26%;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.star:nth-child(4) {
  margin: 350px 0 0 -20%;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.star:nth-child(5) {
  margin: 30px 0 0 10%;
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}

@-webkit-keyframes star-tail {
  0% {
    width: 0;
  }
  10% {
    width: 0;
  }
  20% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@-webkit-keyframes star-shooting {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    opacity: 0;
  }
}

@keyframes star-tail {
  0% {
    width: 0;
  }
  10% {
    width: 0;
  }
  20% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@keyframes star-shooting {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    opacity: 0;
  }
}

.stars {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.stars i {
  position: absolute;
}

.stars i:nth-child(1) {
  left: 10%;
  top: 10%;
}

.stars i:nth-child(2) {
  left: 30%;
  top: 30%;
}

.stars i:nth-child(3) {
  right: 16%;
  top: 35%;
}

.stars i:nth-child(4) {
  right: 30%;
  top: 55%;
}

.stars i:nth-child(5) {
  right: 44%;
  top: 15%;
}

.stars i:nth-child(6) {
  right: 60%;
  top: 85%;
}

.stars i:nth-child(7) {
  left: 20%;
  top: 70%;
}

.stars i:nth-child(8) {
  left: 5%;
  top: 50%;
}

.stars i:nth-child(9) {
  right: 24%;
  top: 6%;
}

.stars i:nth-child(10) {
  right: 5%;
  top: 70%;
}

.stars i:nth-child(11) {
  left: 38%;
  top: 12%;
}

.stars i:nth-child(12) {
  right: 20%;
  top: 80%;
}

.star-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #443a9a transparent transparent transparent;
}

.star-square {
  width: 8px;
  height: 8px;
  background: #443a9a;
  transform: rotateZ(45deg);
}

.house-item-back, .house-item-head, .house-item-layer1, .house-item-layer2, .house-item-layer3, .house-item-layer4, .house-item-layer5, .house-item-layer6, .house-item-side {
  position: absolute;
  top: 110% !important;
  height: 2000px;
  z-index: 5;
}

.mobile-fix {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50vh;
  background: #020716;
  z-index: 8;
}

.add-mobile-fix .mobile-fix {
  display: block;
}

#fluid {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 150vh;
}


#bg_canvas {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    overflow: hidden;
    z-index: 0;
    /*background-color: black;*/
}

.headline_wrapper{
    position: absolute;
    width: 80%;
    height: 100%;
    display: flex;
    z-index: 1;
    pointer-events: none;
}

.outline {
transform-origin:"50% 50%";
}

#service {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

/*THREE CARDS*/

.card_container {
  position: relative;
  z-index: 3;
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(450px, min-content));
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  box-sizing: border-box;


}

.card_container .card {
  position: relative;
  width: auto;
  margin: 20px;
  border-radius: 12px;
  transition: 0.5s;
  overflow: hidden;
  backdrop-filter: blur(25px) saturate(200%);
  -webkit-backdrop-filter: blur(25xp) saturate(200%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}


.card_container .card .content {
  text-align: center;
}

.card_headline {
    margin-top: 2em;
    margin-bottom: 1em;
    height: 3em;
}

.card_container .card .content p {

  padding: 1em;
  padding-top: 4px;
  padding-bottom: 4px;
  /*color: #fff;*/
  text-align: justify;
  font-size: calc(16px + (22 - 16)*(100vw - 300px)/(1920 - 300));
  font-family: "PoiretOne";
  font-weight: 800;
  line-height:1.7em;
}

#card1 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23e80858' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00'/%3E%3Cstop offset='1' stop-color='%23e80858'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0px 10px 57px -13px #00F1BF00;
  height: fit-content;
}
#card2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23362c84' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff'/%3E%3Cstop offset='1' stop-color='%23362c84'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0px 10px 57px -13px #05F53300;
}
#card3 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%2335ec72' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff'/%3E%3Cstop offset='1' stop-color='%2335ec72'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0px 10px 57px -13px rgba(0, 0, 0, 0);
}

/*
.elementor-2934
    .elementor-element.elementor-element-d0688e9
    .elementor-field-group:not(.elementor-field-type-upload)
    .elementor-field:not(.elementor-select-wrapper)::-webkit-input-placeholder {
    /* Chrome and Safari
    color: #0aff8aa8;
    font-family: 'resin';
    font-size: 20px;
    font-style: italic;
}

.elementor-2934
    .elementor-element.elementor-element-d0688e9
    .elementor-field-group:not(.elementor-field-type-upload)
    .elementor-field:not(.elementor-select-wrapper)::-moz-placeholder {
    /* Mozilla Firefox 4 to 18
    color: #0aff8aa8;
    opacity: 0.7;
    font-family: 'resin';
    font-size: 19px;
    font-style: italic;
}

.elementor-2934
    .elementor-element.elementor-element-d0688e9
    .elementor-field-group:not(.elementor-field-type-upload)
    .elementor-field:not(.elementor-select-wrapper)::-moz-placeholder {
    /* Mozilla Firefox 19+
    color: #0aff8aa8;
    opacity: 0.7;
    font-family: 'resin';
    font-size: 19px;
    font-style: italic;
}

.elementor-2934
    .elementor-element.elementor-element-d0688e9
    .elementor-field-group:not(.elementor-field-type-upload)
    .elementor-field:not(.elementor-select-wrapper)::-ms-input-placeholder {
    /* Internet Explorer 10-11
    color: #0aff8aa8;
    font-family: 'resin';
    font-size: 19px;
    font-style: italic;
}

.elementor-2934
    .elementor-element.elementor-element-d0688e9
    .elementor-field-group:not(.elementor-field-type-upload)
    .elementor-field:not(.elementor-select-wrapper)::-ms-input-placeholder {
    /* Microsoft Edge
    color: #0aff8aa8;
    font-family: 'resin';
    font-size: 19px;
    font-style: italic;
}
*/
@media screen and (max-width: 1024px) {

  .menu_desktop, .fun {
    /*visibility: hidden;*/
    display: none;
  }

  .menu_mobile {
  display: flex;
}


  .wrapper {
    display: flex;
    justify-content: center;
    will-change: auto;
    /*align-items: end;*/
  }
  #menew {
    position: relative;
    display: flex;
    left: 0.4rem;
    mix-blend-mode: normal;
    opacity: 1;
  }
  h1.splitted_lines {
    overflow: hidden;
  }
  .splitted_chars {
    font-size: var(--num);
    text-decoration: none;
    color: #bacb83;
    text-shadow: none;
    -webkit-text-stroke: 1px black;
  }
  .container {
    justify-content: center;
    align-items: end;
  }
  .textblock {
    width: 100vw;
    justify-content: center;
    align-items: center;
    right: auto;
    mix-blend-mode: normal;
  }
  .subt {
    text-align: center;
    /* padding: 0.5em; */
    /*font-family: "Rubik", sans-serif;*/
    -webkit-text-stroke: 0;
    font-size: calc(clamp(0.8rem, 0.7rem + 1.35vw, 2.13rem));
    color: white;
  }
  .text_glass_container {
    width: 90vw;
  }
  .footer_section {
    margin-top: -60vh;
    z-index: 75;
  }
  .text_glass {
  box-shadow: none;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .textblock{
    margin-bottom: 0;
  }
  .subt {
    margin-top: 0;
    padding: 0.2em;
  }
  .footer_section {
    margin-top: -40vh;
    height: 100vh;
  }
  .div6 {
    height: 100vh;
  }
  #footer-mob{
    max-height: 100%;
  }
  .nav_list li {
    height: 25vh;
    line-height:1.1;
  }
}
@media (pointer:coarse){
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  body {
    /* Disables pull-to-refresh action but allows overscroll glow effects.*/
    overscroll-behavior-y: contain;
  }
}

