@font-face {
  font-family: in;
  src: url("in.8cc9781d.woff2") format("woff2");
  font-display: optional;
  font-weight: normal;
  font-style: italic;
  unicode-range: U+??;
}

@font-face {
  font-family: brush;
  src: url("brush.17d3df81.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+??;
}

@font-face {
  font-family: out;
  src: url("out.911c10f9.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  unicode-range: U+??;
}

@font-face {
  font-family: Bandeins Strange Variable;
  src: url("BandeinsStrangeVariable.f86c10cc.woff2") format("woff2"), url("BandeinsStrangeVariable.21e53a2c.ttf") format("ttf");
  font-weight: 300 700;
  font-stretch: 100% 800%;
  unicode-range: U+4D, U+45, U+4E, U+55;
}

@font-face {
  font-family: Waiting for the Sunrise;
  src: url("waiting.b1689f14.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+??;
}

@font-face {
  font-family: Kenjo II;
  src: url("KenjoII.a33e6feb.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+??;
}

@font-face {
  font-family: FontAwesome;
  src: url("fontawesome-webfont.43166984.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RoadRage;
  src: url("RoadRage.ed6c7ee3.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+??;
}

@font-face {
  font-family: PoiretOne;
  src: url("PoiretOne.ed0808a0.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

::placeholder {
  all: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

ul {
  margin-block: 0;
  margin-inline: 0;
  padding-inline-start: 0;
  display: block;
}

.gs-dev-tools {
  z-index: 1000;
}

:root {
  --diag: math hypot(vw, vh);
  --mewidth: calc((90vh * 1.2573) / 3.8);
  --meleft: calc(clamp(0%, 90vh - 90vw, var(--mewidth)) );
  --margin-height: calc(-58 * (82vh / 100) );
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-size: 16px;
}

body, html {
  margin: 0;
  padding: 0;
}

body {
  --acc-color: #bacb83;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #040419;
  overflow: hidden scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.scrollbar-track {
  display: none !important;
}

.scroller {
  height: 100vh;
}

#id04:checked ~ .animationToggle {
  background-color: #000000f2;
  transition: transform .75s ease-in-out;
  transform: rotate(360deg);
}

#id04:unchecked ~ .animationToggle {
  background-color: #0000;
  transition: transform .75s ease-in-out;
  transform: rotate(0);
}

#id04:checked ~ .animationToggle:before {
  transition: transform .75s ease-in-out .5s;
  transform: translateX(1.75rem);
}

label[for="id04"] {
  width: 3rem;
  height: 1.25rem;
  transform-origin: 50%;
  cursor: pointer;
  pointer-events: all;
  z-index: 2;
  border: 2.3px solid #f1f377;
  border-radius: .75rem;
  align-items: center;
  transition: transform .75s ease-in-out;
  display: inline-flex;
  position: relative;
}

label[for="id04"]:before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-image: linear-gradient(to top right, #01ffff, #c601e6);
  border-radius: .75rem;
  transition: transform .75s .5s;
  transform: translateX(0);
}

.cls-1 {
  display: none;
}

@media (max-width: 1024px) {
  .loading-word {
    font-size: 6vw;
  }
}

@media (max-width: 767px) {
  .loading-word {
    font-size: 8vw;
  }
}

.loading-path {
  color: #161616;
  vertical-align: top;
  filter: drop-shadow(10px 19px 3px #0006);
  display: inline-block;
  position: relative;
}

@media (max-width: 767px) {
  .loading-path {
    font-size: 10px;
  }
}

.loading-line {
  width: 100vw;
  height: 30px;
  background-color: #bacb83;
  display: block;
  position: relative;
}

.button--grad {
  cursor: pointer;
  pointer-events: all;
  color: #000;
  filter: drop-shadow(2px 4px 4px #0000004d) drop-shadow(4px 8px 8px #0000004d);
  background: linear-gradient(45deg, #dbafff61 0%, #24d5ff59 100%);
  border: 1px solid #dbafff2b;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .7rem 2rem;
  font-family: in;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
}

.button--grad span {
  z-index: 1;
  display: block;
  position: relative;
}

.button--grad > span {
  overflow: hidden;
}

.button--grad:hover > span > span {
  animation: .15s forwards MoveRightInitial, .35s .25s forwards MoveRightEnd;
}

.button--grad:active {
  filter: none;
  top: 4px;
}

@keyframes MoveRightInitial {
  to {
    transform: translate3d(105%, 0, 0);
  }
}

@keyframes MoveRightEnd {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.button--grad:before, .button--grad:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #3077879e 0%, #dc91ff94 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.button--grad:before {
  width: 135%;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
  transform: translate3d(-100%, 0, 0);
}

.button--grad:hover:before {
  transition: transform .3s cubic-bezier(.7, 0, .2, 1);
  transform: translate3d(0, 0, 0);
}

.button--grad:after {
  width: 105%;
  transition: transform .3s cubic-bezier(.7, 0, .2, 1);
  transform: translate3d(100%, 0, 0);
}

.button--grad:hover:after {
  transition: transform 10ms cubic-bezier(.7, 0, .2, 1) .3s;
  transform: translate3d(0, 0, 0);
}

.services-ticker-block {
  align-items: center;
  display: none;
  position: relative;
  overflow-x: hidden;
  transform: translate(0, 100%);
}

#mycanvas {
  z-index: 0;
  pointer-events: none;
  opacity: 1;
  background-image: url("noanimation.784c2348.webp");
  background-size: cover;
  display: block;
  position: fixed;
  inset: 0;
  overflow: hidden;
  height: 100vh !important;
  width: 100vw !important;
}

.wrangler {
  width: 100%;
  height: 100%;
}

.grad {
  background: linear-gradient(131deg, #9df9fd 25%, #ffd79b 35%, #d27a3b 44%, #080808 86%, #fff 100%) !important;
}

.staticCloudBg {
  background-image: url("noanimation.784c2348.webp");
  background-size: cover;
}

.close-container {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 40;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.leftright {
  height: 3px;
  width: 60%;
  background-color: #f3eded99;
  border-radius: 1.5px;
  transition: all .3s ease-out;
  position: absolute;
  transform: rotate(45deg);
}

.rightleft {
  height: 3px;
  width: 60%;
  background-color: #f3eded99;
  border-radius: 1.5px;
  transition: all .3s ease-out;
  position: absolute;
  transform: rotate(-45deg);
}

.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright {
  background-color: #f3eded99;
  transform: rotate(225deg);
}

.close-container:hover .rightleft {
  background-color: #f3eded99;
  transform: rotate(135deg);
}

#contact_form {
  z-index: 510;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  pointer-events: none;
  background-color: #0000;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.form_before, .form_after {
  visibility: visible;
  overflow: hidden;
}

#form_container {
  height: 66%;
  width: 68%;
  pointer-events: all;
  background: #3c343447;
  border: 1px solid #ffffff1a;
  border-color: #ffffff40 #ffffff1a #ffffff1a #ffffff40;
  border-radius: 10px;
  flex-flow: column;
  place-content: flex-end center;
  align-items: stretch;
  padding: 50px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  box-shadow: 0 25px 45px #0000001a;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  #form_container {
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  #form_container {
    background-color: #3b3838cc;
  }
}

#form_container:before {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #ffffff0d;
  position: absolute;
  top: 0;
  left: -40%;
  transform: skew(-15deg);
}

.marquee {
  font-family: out;
  font-size: 4.5vmax;
  overflow: hidden;
}

.marquee--long {
  text-transform: uppercase;
  font-size: 3vmax;
}

.marquee span {
  white-space: nowrap;
  color: #ffffffc2;
  width: var(--tw);
  text-shadow: var(--tw) 0 currentColor, calc(var(--tw) * 2) 0 currentColor, calc(var(--tw) * 3) 0 currentColor, calc(var(--tw) * 4) 0 currentColor;
  will-change: transform;
  display: inline-block;
}

#form_container .row100 {
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
  position: relative;
}

#form_container .row100 .col {
  width: 100%;
  justify-content: center;
  margin: 30px 0 10px;
  display: flex;
  position: relative;
}

#form_container .row100 .inputBox {
  width: 100%;
  height: 40px;
  color: #fff;
  position: relative;
  overflow: hidden;
}

#form_container .row100 .inputBox input, #form_container .row100 .inputBox.textarea textarea {
  width: 90%;
  height: 100%;
  box-shadow: none;
  z-index: 1;
  color: #fff;
  background: none;
  border: none;
  outline: none;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 14px;
  position: absolute;
}

#form_container .row100 .inputBox .text {
  pointer-events: none;
  padding: 0 10px;
  font-family: roadrage;
  font-size: 20px;
  line-height: 40px;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#form_container .row100 .inputBox input:focus + .text, #form_container .row100 .inputBox input:valid + .text {
  top: -35px;
  left: -10px;
}

#form_container .row100 .inputBox .line {
  width: 0%;
  height: 2px;
  pointer-events: none;
  background: #f5e9e93b;
  border-radius: 2px;
  transition: all .5s cubic-bezier(.175, .885, .32, 1.275);
  display: block;
  position: absolute;
  bottom: 0;
}

#form_container .row100 .inputBox input:focus ~ .line, #form_container .row100 .inputBox input:valid ~ .line {
  height: 100%;
}

#form_container .row100 .inputBox.textarea {
  width: 100%;
  height: 100px;
  padding: 10px 0;
  position: relative;
}

#form_container .row100 .inputBox.textarea textarea {
  height: 100%;
  resize: none;
}

#form_container .row100 .inputBox textarea:focus + .text, #form_container .row100 .inputBox textarea:valid + .text {
  top: -35px;
  left: -10px;
}

#form_container .row100 .inputBox textarea:focus ~ .line, #form_container .row100 .inputBox textarea:valid ~ .line {
  height: 100%;
}

input[type="submit"] {
  cursor: pointer;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 2px;
  outline: none;
  padding: 7px 35px;
  font-size: 18px;
}

.footer_section {
  margin-top: var(--margin-height);
  height: 82vh;
  vertical-align: bottom;
  z-index: 1;
  position: absolute;
  overflow: hidden;
}

#glass_icon_list {
  z-index: 70;
  margin-top: calc(var(--part-height) * .125);
  pointer-events: all;
  justify-content: center;
  padding: 0;
  display: flex;
  position: relative;
}

#glass_icon_list li {
  margin: 10px;
  position: relative;
}

#glass_icon_list li button, #glass_icon_list li a {
  width: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
  height: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
  color: #fff;
  backdrop-filter: blur(5px);
  cursor: pointer;
  border: 1px solid #fff3;
  border-color: #fff6 #fff3 #fff3 #fff6;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-size: clamp(1rem, .03rem + 6.18vw, 3rem);
  text-decoration: none;
  transition: all .8s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 45px #0000001a;
}

#glass_icon_list li button:hover, #glass_icon_list li a:hover {
  transform: translateY(10px);
}

#glass_icon_list li button:before, #glass_icon_list li a:before {
  content: "";
  width: 50%;
  height: 100%;
  background: #ffffff80;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(45deg)translateX(150px);
}

#glass_icon_list li button:hover:before, #glass_icon_list li a:hover:before {
  transform: skewX(45deg)translateX(-150px);
}

.elementor-element.elementor-element-79ddde24 {
  min-height: 100vh;
  z-index: 11;
  margin-top: 0;
  margin-bottom: 0;
  padding: 2em;
}

.elementor-element-cbafa4c {
  pointer-events: none;
}

.elementor-element-5321b1b4 {
  margin-top: -47.5vh;
}

#tsparticles > canvas {
  height: 100%;
  grid-area: 1 / 1 / 3 / 7;
  position: relative !important;
}

#tsparticles {
  width: 100%;
  height: 82vh;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  vertical-align: bottom;
  z-index: 11;
  --part-height: 100%;
  background: linear-gradient(0deg, #000 0% 42%, #fff0 60%);
  grid-template-rows: 28.55% 29.45% 42%;
  grid-template-columns: 4.73% 40.52% repeat(2, 4.73%) 40.52% 4.73%;
  align-items: stretch;
  display: grid;
  position: absolute;
}

.div1 {
  grid-area: 3 / 1 / 4 / 7;
}

.div2 {
  grid-area: 2 / 2 / 3 / 3;
}

.div3 {
  grid-area: 2 / 5 / 3 / 6;
}

.div4 {
  grid-area: 3 / 5 / 3 / 6;
}

.div5 {
  grid-area: 3 / 5 / 4 / 6;
}

.wrapper-footer {
  z-index: 75;
  width: 100vw;
  vertical-align: bottom;
}

#footer-bg {
  width: 100%;
  object-fit: unset;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  background: #000;
}

#pyra_l {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: auto;
  margin-bottom: -1em;
}

#pyra_r {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: auto;
  z-index: 60;
  margin-bottom: -1em;
}

.div2 {
  z-index: 20;
  vertical-align: bottom;
  align-self: self-end;
}

.div3 {
  z-index: 20;
  align-self: self-end;
}

.div4 {
  width: 9rem;
  height: 9rem;
  z-index: -1;
  justify-self: right;
}

#mond {
  width: 100%;
  height: auto;
}

#arrowup_mobile {
  width: 2em;
  height: 2em;
  z-index: 19;
  cursor: pointer;
  opacity: .7;
  animation: 5s infinite updown;
  position: absolute;
  bottom: 11vh;
  right: 1em;
}

#arrowup {
  width: 2em;
  height: 2em;
  z-index: 19;
  cursor: pointer;
  opacity: .7;
  animation: 5s infinite updown;
  position: absolute;
  bottom: 15vh;
  right: 2em;
}

@keyframes updown {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(0, 40px);
  }

  100% {
    transform: translate(0);
  }
}

.lve {
  z-index: 18;
  height: auto;
  width: 100%;
  color: #8179b68f;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: .2em;
  font-family: roadrage;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.lve p {
  margin-block-end: 0;
}

.part, .part_mob {
  display: none;
}

#social-icons {
  z-index: 70;
  margin-top: calc(var(--part-height) * .125);
  justify-content: center;
  padding: 0;
  display: flex;
  position: relative;
}

.div6 ul li {
  margin: 10px;
  position: relative;
}

.div6 ul li a {
  width: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
  height: clamp(2.5rem, 1.29rem + 7.72vw, 5rem);
  color: #fff;
  backdrop-filter: blur(5px);
  border: 1px solid #fff3;
  border-color: #fff6 #fff3 #fff3 #fff6;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-size: clamp(1rem, .03rem + 6.18vw, 3rem);
  text-decoration: none;
  transition: all .8s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 45px #0000001a;
}

.div6 ul li a:hover {
  transform: translateY(10px);
}

.div6 ul li a:before {
  content: "";
  width: 50%;
  height: 100%;
  background: #ffffff80;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewX(45deg)translateX(150px);
}

.div6 ul li a:hover:before {
  transform: skewX(45deg)translateX(-150px);
}

.git-wrap {
  grid-area: 3 / 2 / 4 / 3;
}

.git-wrap > p:nth-child(1) {
  margin-top: calc(var(--part-height) * .125);
  background: linear-gradient(7deg, #4ff7b273 43%, #5012a3 59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #5d8b6338;
  text-align: center;
  -webkit-text-stroke: 1px #bacb83;
  font-family: in;
  font-size: clamp(3.19rem, 1.98rem + 1.9vw, 4.25rem);
}

@media screen and (max-width: 1023px) {
  .wrapper-footer {
    display: none;
  }

  .part_mob {
    width: 100vw;
    z-index: 1000;
    flex-direction: column;
    display: flex;
  }

  #footer-mob {
    object-fit: unset;
    max-height: 90%;
    max-width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
  }

  .wrap {
    z-index: 80;
    height: auto;
    width: 100%;
    flex-flow: column wrap;
    justify-content: end;
    display: flex;
  }

  .div6 {
    height: 82vh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .elementor-element-1c4139f2 {
    z-index: 500;
    margin-top: -65vh;
  }

  .getintouch {
    z-index: 70;
    background: linear-gradient(7deg, #26d560c7 43%, #8179b6 59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #72bde985;
    -webkit-text-stroke: 1px #bacb83;
    align-self: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px;
    font-family: in;
    font-size: clamp(1.63rem, 1.18rem + 2.84vw, 3rem);
    font-weight: 300;
  }

  .div6 ul {
    z-index: 70;
    margin-top: calc(var(--part-height) * .125);
    margin-bottom: 8%;
    padding: 0;
    display: flex;
    position: relative;
  }

  #form_container {
    height: 90%;
    max-width: 84vw;
    width: 84vw;
    padding: 1em;
  }

  #menew {
    mix-blend-mode: normal;
  }
}

.menu_mobile {
  height: 100vh;
  width: 100%;
  scroll-behavior: smooth;
  clip-path: circle(60px at 100% -10%);
  opacity: 1;
  pointer-events: all;
  background-color: #000;
  justify-content: center;
  align-items: center;
  font-family: Kenjo II;
  font-size: clamp(4.06rem, 2.61rem + 7.74vw, 8.13rem);
  display: none;
  position: fixed;
  top: 0;
  right: 0;
}

.popover:target {
  width: 100%;
  height: 100%;
  justify-content: left;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.popover .content {
  width: 100%;
  height: 0;
  color: #fff;
  text-align: left;
  justify-content: left;
  align-items: center;
  animation: 1s forwards grow;
  display: flex;
  position: relative;
}

.nav_list {
  padding-inline-start: 0;
}

.nav_list a {
  color: #fff;
  font-size: max(16vw, 30px);
  text-decoration: none;
}

.nav_list_item {
  height: 100%;
  overflow: hidden;
}

.nav_list li {
  text-transform: uppercase;
  opacity: 1;
  letter-spacing: -.1em;
  margin: -.1em 0;
  line-height: 1.2;
  position: relative;
  overflow: hidden;
  transform: translateY(200px);
}

.nav_list li:before {
  content: "";
  height: 2px;
  width: 0;
  background: #bf2e97;
  transition: all .5s;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.nav_list li:hover:before {
  width: 100%;
}

.popover p {
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 50px;
  animation: 1s 1s forwards fadeIn;
}

.popover .close:after {
  width: 50px;
  height: 50px;
  z-index: 1;
  color: #fff;
  content: "×";
  cursor: pointer;
  opacity: 0;
  background-color: #bf2e97;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  animation: 1s .5s forwards fadeIn;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

@keyframes grow {
  100% {
    height: 90%;
    width: 90%;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.journey_section {
  z-index: 100;
}

.text_glass_container {
  max-width: 100vw;
  min-height: 150vh;
  width: 60vw;
  flex-flow: column;
  place-content: center flex-start;
  align-items: center;
  display: flex;
}

.text_glass {
  z-index: 3;
  backdrop-filter: blur(25px) contrast(.8);
  height: fit-content;
  visibility: hidden;
  word-break: break-word;
  background: #0000006b;
  border: 1px solid #ffffff1a;
  border-color: #ffffff40 #ffffff1a #ffffff1a #ffffff40;
  border-radius: 10px;
  flex-flow: column;
  justify-content: center;
  align-self: center;
  align-items: stretch;
  display: flex;
  overflow: hidden;
  box-shadow: 0 25px 45px #00000054;
}

.text_glass > * {
  color: #fff;
  text-shadow: 1px 0 #d3d3d3;
  will-change: transform;
  margin: 0 0 0;
  padding: 0 1em;
  font-family: Waiting for the Sunrise, sans-serif;
  font-size: clamp(1.25rem, .3rem + 1.19vw, 2.38rem);
  font-weight: 200;
}

.text_glass_wrap {
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-flow: column;
  place-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.hero-container {
  width: 100%;
  height: 100vh;
  background: none;
  align-items: flex-end;
  display: flex;
  overflow-x: hidden;
}

.svg-container {
  width: 100vw;
  height: 100vh;
  z-index: 0;
  bottom: 0%;
}

#menew {
  width: auto;
  height: 90vh;
  bottom: 0;
  left: calc(-1 * var(--meleft));
  z-index: 15;
  opacity: .8;
  grid-area: 1;
  position: absolute;
}

.wrapper {
  width: 100%;
  grid-template-rows: minmax(50vh, 100vh);
  display: grid;
  position: absolute;
  overflow: hidden;
}

.textblock {
  pointer-events: auto;
  z-index: 15;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
  display: flex;
  position: absolute;
  bottom: calc(clamp(.5rem, 13vw - 6rem, 50vh));
  right: calc(clamp(5.81rem, 17.6rem - 12.07vw, 3.13rem));
}

.subt {
  z-index: 16;
  color: #ddefa1;
  text-align: center;
  transform-origin: 50%;
  max-width: 100%;
  -webkit-text-stroke: .7px #0d0206;
  margin-top: .5em;
  font-family: brush;
  font-size: calc(clamp(.8rem, .6rem + 1.35vw, 1.93rem));
  font-weight: 300 !important;
}

.subt_line {
  width: 100%;
  background-color: #0000;
  line-height: 1.1;
  overflow: hidden;
}

.subt_word {
  display: inline-flex;
}

.subt_chars {
  perspective: 500px;
  backface-visibility: visible;
  transform-origin: 50%;
  display: inline-block;
  position: relative;
  transform: rotateX(-360deg);
}

h1.splitted_lines {
  padding: 0 .125em .5em;
  display: flex;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin: 0;
  margin-block: 0;
  margin-inline: 0;
  padding: 0;
  font-family: inherit;
  font-weight: 500;
}

.splitted_words {
  display: flex;
  position: relative;
}

div.splitted_words:last-child {
  margin-left: .5em;
}

.splitted_chars {
  transform-origin: 50%;
  z-index: 14;
  height: 1em;
  color: #2c242b40;
  text-align: left;
  text-shadow: 2px 2px 4px #00000085;
  letter-spacing: 0;
  --num: calc(clamp(1.88rem, 1.55rem + 3vw, 8.5rem) );
  font-family: in;
  font-weight: 500;
  font-size: var(--num);
  text-transform: uppercase;
  -webkit-text-stroke: 2px #bacb83bd;
  will-change: transform;
  display: inline-block;
  position: relative;
}

.liquid_menu_final {
  width: 100vw;
  z-index: 85;
  position: fixed;
}

canvas {
  height: 100%;
  width: 100%;
}

.header_box {
  z-index: 90;
  width: 98vw;
  height: 8vh;
  flex-direction: row-reverse;
  place-content: stretch space-between;
  align-self: center;
  align-items: center;
  margin: auto auto -8vh;
  display: flex;
  top: 0;
}

#hamburger__container, #animationToggle__container {
  display: flex;
}

#hamburger__container {
  width: 4.7rem;
  height: 3.4rem;
  z-index: 2;
}

.hamburger {
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: all;
  cursor: pointer;
  backdrop-filter: blur(30px);
  background: #31313161;
  border-radius: 5%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.menu_word {
  color: #c9ef54ad;
  font-variation-settings: "wght" 700, "wdth" 800;
  font-family: Bandeins Strange Variable;
  font-size: 11px;
  position: absolute;
  top: 33px;
}

.hamburger > div {
  width: 60%;
  height: 2px;
  background-color: var(--acc-color);
  border-radius: 1.5px;
  transition: all .4s;
  position: absolute;
}

.hamburger > div:before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--acc-color);
  border-radius: 1.5px;
  transition: all .4s;
  position: absolute;
  top: -10px;
  left: 0;
}

.toggler:checked + .hamburger > div {
  transform: rotate(135deg);
}

.toggler:checked:hover + .hamburger > div {
  transform: rotate(225deg);
}

.toggler:checked + .hamburger > div:before, .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}

#demo {
  z-index: 250;
}

.menu_desktop {
  z-index: 200;
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.fun {
  z-index: 210;
  width: 85vw;
  height: 85vw;
  text-align: center;
  pointer-events: all;
  background-image: url("5.09831893.webp");
  background-size: cover;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: Kenjo II;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(0);
}

.cursor {
  width: 3rem;
  height: 3rem;
  pointer-events: none;
  z-index: 99999;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.menu_desktop > div > div {
  max-width: 100vw;
  max-height: 100vh;
  opacity: 1;
  transition: all .4s;
}

.menu_desktop > div > div > ul > li > a {
  color: #fff;
  text-transform: uppercase;
  margin: .5rem;
  font-family: Kenjo II, Regular;
  font-size: calc(clamp(1.375rem, .4646rem + 8.6024vw, 7.1875rem));
  font-weight: 550;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.menu_desktop > div > div > ul > li > a:hover {
  color: #fff44f;
  transition-property: transform;
  transition-duration: .3s;
  transform: scale(1.3);
}

.global-menu__item {
  height: 100%;
  width: 100%;
  margin: -.4rem 0;
  overflow: hidden;
}

.Menu-list {
  text-transform: uppercase;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: clamp(4.5rem, 2.3rem + 7.04vw, 10.75rem);
  line-height: 1;
  display: flex;
  transform: rotateX(20deg)rotateY(340deg);
}

.Menu-list-item {
  color: #0000;
  cursor: pointer;
  letter-spacing: -.1em;
  margin-bottom: -.4rem;
  line-height: .9;
  position: relative;
  transform: translate(0, -100%);
}

.Menu-list-item:before {
  content: "";
  height: 4px;
  z-index: 1;
  background: #ff2c75;
  border-radius: 4px;
  margin-top: -2px;
  transition: transform .8s cubic-bezier(0, -.39, .95, 1.52);
  display: block;
  position: absolute;
  top: 49%;
  left: -10%;
  right: -10%;
  transform: scale(0);
}

.Mask {
  color: #ff2c75;
  height: 49%;
  width: 100%;
  transition: all .6s cubic-bezier(1, .1, 1, 1);
  display: block;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.Mask .Mask, .Mask a {
  color: #fff;
}

.Mask span {
  display: block;
}

.Mask + .Mask {
  height: 51.1%;
  top: 48.9%;
}

.Mask + .Mask span {
  transform: translateY(-49%);
}

.Menu-list-item:hover .Mask, .Menu-list-item:hover a, .Menu-list-item:active .Mask, .Menu-list-item:active a {
  color: #fff44f;
  transform: skewX(12deg)translateX(5px);
}

.Menu-list-item:hover .Mask + .Mask, .Menu-list-item:active .Mask + .Mask {
  transform: skewX(12deg)translateX(-5px);
}

.Menu-list-item:hover:before, .Menu-list-item:active:before {
  transform: scale(1);
}

.uc_liquis_copy_bg_addon29003 {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.container__cards {
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  grid-template-rows: repeat(auto-fit, minmax(450px, min-content));
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  display: grid;
  position: relative;
}

.container__cards .card {
  width: auto;
  backdrop-filter: blur(25px) saturate(200%);
  -webkit-backdrop-filter: blur(25xp) saturate(200%);
  box-sizing: border-box;
  border-radius: 12px;
  margin: 20px;
  transition: all .5s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

@media (hover: none), (hover: on-demand) {
  .container__cards .card:hover {
    transform: translateY(-10px);
  }
}

.container__cards .card .content {
  text-align: center;
  opacity: 1;
  transition: all .5s;
  transform: translateZ(20px);
}

.container__cards .card .content h1 {
  color: #fff;
  text-align: center;
  margin-top: .5em;
  margin-bottom: .5em;
  font-size: calc(1.5vw + 19.2px);
  transition: all .5s;
  transform: translateZ(20px);
}

.container__cards .card .content p {
  text-align: justify;
  margin-top: 0;
  margin-bottom: .9rem;
  padding: 4px 1em;
  font-size: calc(.25vw + 17.2px);
}

#card1 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23e80858' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00'/%3E%3Cstop offset='1' stop-color='%23e80858'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0 10px 57px -13px #00f1bf00;
}

#card2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23362c84' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff'/%3E%3Cstop offset='1' stop-color='%23362c84'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0 10px 57px -13px #05f53300;
}

#card3 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%2335ec72' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff'/%3E%3Cstop offset='1' stop-color='%2335ec72'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0 10px 57px -13px #0000;
}

#wp-custom-css > .leiste {
  backdrop-filter: blur(20px) saturate 200%;
  -webkit-backdrop-filter: blur(20px) saturate 200%;
}

.glitch-typo, .glitch-typo-second {
  white-space: nowrap;
  -o-transition: all .2s ease-in-out;
  text-decoration: none;
  transition: all .2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.glitch-typo-second:hover, .glitch-typo:hover {
  text-decoration: none;
}

.glitch-typo-second:hover:before, .glitch-typo:hover:before {
  animation: 2s cubic-bezier(0, .82, 1, .42) .3s both glitch-typo;
}

.glitch-typo-second:hover:after, .glitch-typo:hover:after {
  animation: 2s cubic-bezier(0, .82, 1, .42) .4s reverse both glitch-typo;
}

.glitch-typo-second:hover span, .glitch-typo:hover span {
  animation: .5s ease-in-out .1s forwards glitch-typo-scale;
}

.glitch-typo-second:after, .glitch-typo-second:before, .glitch-typo:after, .glitch-typo:before {
  content: attr(data-title);
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -2;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.glitch-typo-second:before, .glitch-typo:before {
  color: #4ff7b2;
}

.glitch-typo-second:after, .glitch-typo:after {
  color: #ef00d8;
}

.glitch-typo span, .glitch-typo-second span {
  z-index: -1;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  display: inline-block;
  position: relative;
}

.glitch-typo-second:before {
  color: #6488dc;
}

@keyframes glitch-typo {
  0% {
    opacity: 0;
    transform: translate(0);
  }

  18% {
    opacity: 0;
    transform: translate(0);
  }

  20% {
    opacity: .6;
    transform: translate(-10px, 10px);
  }

  34% {
    opacity: 0;
    transform: translate(-16px, -10px);
  }

  55% {
    opacity: .4;
    transform: translate(0);
  }

  60% {
    opacity: .8;
    transform: translate(-10px, 10px);
  }

  64% {
    opacity: .4;
    transform: translate(10px);
  }

  70% {
    opacity: 0;
    transform: translate(0);
  }

  to {
    opacity: 0;
    transform: translate(0);
  }
}

@keyframes glitch-typo-scale {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.2);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

header h1 .logo-1 {
  opacity: 1;
}

header h1 .logo-2 {
  -webkit-filter: hue-rotate(60deg) saturate(4);
  filter: hue-rotate(60deg) saturate(4);
  animation: 8s cubic-bezier(0, .82, 1, .42) infinite reverse both glitch-logo;
}

header h1 .logo-3 {
  -webkit-filter: hue-rotate(270deg) saturate(4);
  filter: hue-rotate(270deg) saturate(4);
  animation: 10s cubic-bezier(0, .82, 1, .42) infinite both glitch-logo;
}

@keyframes glitch-logo {
  0% {
    opacity: 0;
    transform: translate(0);
  }

  19% {
    opacity: 0;
    transform: translate(0);
  }

  22% {
    opacity: .4;
    transform: translate(-10px, 10px)skew(-10deg);
  }

  26% {
    opacity: 0;
    transform: translate(-10px, -20px);
  }

  27% {
    opacity: 0;
    transform: translate(0);
  }

  52% {
    opacity: 0;
    transform: translate(0);
  }

  56% {
    opacity: .8;
    transform: translate(-10px, 10px)skew(10deg);
  }

  57% {
    opacity: .4;
    transform: translate(20px);
  }

  58% {
    opacity: 0;
    transform: translate(0);
  }

  to {
    opacity: 0;
    transform: translate(0);
  }
}

.footer_stars {
  width: 100%;
  height: auto;
  grid-area: 1 / 1 / 3 / 7;
  display: grid;
  bottom: 0;
  left: 0;
  transform: rotateZ(24deg);
}

.shooting-stars {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateZ(24deg);
}

.star {
  height: 4px;
  -webkit-filter: drop-shadow(0 0 6px #fff);
  filter: drop-shadow(0 0 6px #fff);
  background: linear-gradient(-45deg, #fff, #00f0);
  border-radius: 999px;
  animation: 10s ease-in-out infinite star-tail, 10s ease-in-out infinite star-shooting;
  position: absolute;
  top: 50%;
  left: 50%;
}

.star:nth-child(1) {
  margin: 500px 0 0 -38%;
  animation-delay: .5s;
}

.star:nth-child(2) {
  margin: 166px 0 0 -10%;
  animation-delay: 3s;
}

.star:nth-child(3) {
  margin: -140px 0 0 26%;
  animation-delay: 2s;
}

.star:nth-child(4) {
  margin: 350px 0 0 -20%;
  animation-delay: 5s;
}

.star:nth-child(5) {
  margin: 30px 0 0 10%;
  animation-delay: 8s;
}

@keyframes star-tail {
  0% {
    width: 0;
  }

  10% {
    width: 0;
  }

  20% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes star-shooting {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  10% {
    opacity: 1;
    transform: translateX(0);
  }

  20% {
    opacity: 0;
    transform: translateX(300px);
  }

  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

.stars {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.stars i {
  position: absolute;
}

.stars i:nth-child(1) {
  top: 10%;
  left: 10%;
}

.stars i:nth-child(2) {
  top: 30%;
  left: 30%;
}

.stars i:nth-child(3) {
  top: 35%;
  right: 16%;
}

.stars i:nth-child(4) {
  top: 55%;
  right: 30%;
}

.stars i:nth-child(5) {
  top: 15%;
  right: 44%;
}

.stars i:nth-child(6) {
  top: 85%;
  right: 60%;
}

.stars i:nth-child(7) {
  top: 70%;
  left: 20%;
}

.stars i:nth-child(8) {
  top: 50%;
  left: 5%;
}

.stars i:nth-child(9) {
  top: 6%;
  right: 24%;
}

.stars i:nth-child(10) {
  top: 70%;
  right: 5%;
}

.stars i:nth-child(11) {
  top: 12%;
  left: 38%;
}

.stars i:nth-child(12) {
  top: 80%;
  right: 20%;
}

.star-triangle {
  width: 0;
  height: 0;
  border: 6px solid #0000;
  border-top-color: #443a9a;
  border-bottom-width: 0;
}

.star-square {
  width: 8px;
  height: 8px;
  background: #443a9a;
  transform: rotateZ(45deg);
}

.house-item-back, .house-item-head, .house-item-layer1, .house-item-layer2, .house-item-layer3, .house-item-layer4, .house-item-layer5, .house-item-layer6, .house-item-side {
  height: 2000px;
  z-index: 5;
  position: absolute;
  top: 110% !important;
}

.mobile-fix {
  width: 100%;
  height: 50vh;
  z-index: 8;
  background: #020716;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.add-mobile-fix .mobile-fix {
  display: block;
}

#fluid {
  width: 100%;
  height: 100%;
  min-height: 150vh;
  flex-direction: column;
  place-content: center flex-start;
  align-items: center;
  display: flex;
}

#bg_canvas {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.headline_wrapper {
  width: 80%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  display: flex;
  position: absolute;
}

.outline {
  transform-origin: "50% 50%";
}

#service {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card_container {
  z-index: 3;
  width: 100%;
  box-sizing: border-box;
  grid-template-rows: repeat(auto-fit, minmax(450px, min-content));
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
  position: relative;
}

.card_container .card {
  width: auto;
  backdrop-filter: blur(25px) saturate(200%);
  -webkit-backdrop-filter: blur(25xp) saturate(200%);
  box-sizing: border-box;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 20px;
  transition: all .5s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card_container .card .content {
  text-align: center;
}

.card_headline {
  height: 3em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.card_container .card .content p {
  text-align: justify;
  padding: 4px 1em;
  font-family: PoiretOne;
  font-size: calc(.37037vw + 14.8889px);
  font-weight: 800;
  line-height: 1.7em;
}

#card1 {
  height: fit-content;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23e80858' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23ffbc00'/%3E%3Cstop offset='1' stop-color='%23e80858'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0 10px 57px -13px #00f1bf00;
}

#card2 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%23362c84' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%23fc00ff'/%3E%3Cstop offset='1' stop-color='%23362c84'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0 10px 57px -13px #05f53300;
}

#card3 {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='grd1' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff' stop-opacity='0.24'/%3E%3Cstop offset='1' stop-color='%2335ec72' stop-opacity='0.24'/%3E%3C/linearGradient%3E%3ClinearGradient id='grd2' gradientUnits='userSpaceOnUse' x1='166.686' y1='303' x2='339.314' y2='4'%3E%3Cstop offset='0' stop-color='%2300d0ff'/%3E%3Cstop offset='1' stop-color='%2335ec72'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='3' y='3' width='100%25' height='100%25' style='height:calc(100%25 - 4px);width:calc(100%25 - 4px)' mix-blend-mode='multiply' fill='url(%23grd1)' stroke='url(%23grd2)' stroke-width='3px' rx='11' ry='11' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-shadow: 0 10px 57px -13px #0000;
}

@media screen and (max-width: 1024px) {
  .menu_desktop, .fun {
    display: none;
  }

  .menu_mobile {
    display: flex;
  }

  .wrapper {
    will-change: auto;
    justify-content: center;
    display: flex;
  }

  #menew {
    mix-blend-mode: normal;
    opacity: 1;
    display: flex;
    position: relative;
    left: .4rem;
  }

  h1.splitted_lines {
    overflow: hidden;
  }

  .splitted_chars {
    font-size: var(--num);
    color: #bacb83;
    text-shadow: none;
    -webkit-text-stroke: 1px black;
    text-decoration: none;
  }

  .container {
    justify-content: center;
    align-items: end;
  }

  .textblock {
    width: 100vw;
    mix-blend-mode: normal;
    justify-content: center;
    align-items: center;
    right: auto;
  }

  .subt {
    text-align: center;
    -webkit-text-stroke: 0;
    color: #fff;
    font-size: calc(clamp(.8rem, .7rem + 1.35vw, 2.13rem));
  }

  .text_glass_container {
    width: 90vw;
  }

  .footer_section {
    z-index: 75;
    margin-top: -60vh;
  }

  .text_glass {
    box-shadow: none;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .textblock {
    margin-bottom: 0;
  }

  .subt {
    margin-top: 0;
    padding: .2em;
  }

  .footer_section {
    height: 100vh;
    margin-top: -40vh;
  }

  .div6 {
    height: 100vh;
  }

  #footer-mob {
    max-height: 100%;
  }

  .nav_list li {
    height: 25vh;
    line-height: 1.1;
  }
}

@media (pointer: coarse) {
  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
    -khtml-user-select: none;
  }

  body {
    overscroll-behavior-y: contain;
  }
}

/*# sourceMappingURL=style.1ffc6e38.css.map */
